<template>
	<section class="rent">
		<div class="container">
			<div
				class="rent__content"
				v-lazy:background="require('@/assets/img/pages/home/rent-bg.png')"
			>
				<h2 class="rent__hdng">
					Сдавать свое<br />
					жилье - просто!
				</h2>
				<span class="rent__text">
					Получайте дополнительный доход от свободного жилья
				</span>
				<tk-button @click="goToOwner" class="rent__btn" kind="main-white">
					Сдать жилье
				</tk-button>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	methods: {
		goToOwner() {
			this.$router.push({ name: 'Owner' })

			setTimeout(() => {
				if (window) {
					window.scrollTo(0, 0)
				}
			})
		}
	}
}
</script>

<style lang="sass">
.rent
	&__content
		display: flex
		flex-direction: column
		align-items: center
		padding: 120px 40px
		border-radius: 20px
		background-position: center
		background-repeat: no-repeat
		background-size: cover

		@media screen and (max-width: 500px)
			padding: 100px 15px 40px 15px

	&__hdng
		margin: 0 0 12px 0
		font-weight: 700
		font-size: 48px
		line-height: 55px
		text-align: center
		color: #FFF

		@media screen and (max-width: 500px)
			font-weight: 700
			font-size: 36px
			line-height: 36px

	&__text
		font-weight: 600
		font-size: 14px
		line-height: 16px
		text-align: center
		color: #FFFFFF

		@media screen and (max-width: 500px)
			display: inline-block
			max-width: 190px

	&__btn
		margin-top: 40px
		width: 214px

		@media screen and (max-width: 500px)
			margin-top: 90px
</style>
